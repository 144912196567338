
import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { RoughnessMipmapper } from 'three/examples/jsm/utils/RoughnessMipmapper.js';
import * as seed from 'seed-random'
const backgroundColor = "#4d8bd4"

// Logo settings
const logoMetalness = 1;
const logoRoughness = 0;

const outOffScreenPos = 300;

let logoScene;


const rotationSpeed = 0.001;

// clouds
const cloudParticles = [];

const randomSeed = 'boiseedyyy';


// DO NOT CHANGE
const random = seed(randomSeed);

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()



/**
 * Models
 */
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')

const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)


new RGBELoader()
    .setDataType(THREE.UnsignedByteType)
    .load('/textures/equirectangular/default.hdr', function (texture) {

        const envMap = pmremGenerator.fromEquirectangular(texture).texture;

        scene.environment = envMap;

        texture.dispose();
        pmremGenerator.dispose();

        const material = new THREE.MeshStandardMaterial({

            color: 0xffffff,

            roughness: logoRoughness,
            metalness: logoMetalness,


            envMap: envMap, // important -- especially for metals!
            envMapIntensity: 1

        });

        // model

        // use of RoughnessMipmapper is optional
        const roughnessMipmapper = new RoughnessMipmapper(renderer);

        const loader = new GLTFLoader().setPath('/models/');
        loader.load('skyx-logo.glb', function (gltf) {

            gltf.scene.traverse(function (child) {
                if (child.isMesh) {
                    child.material = material;
                    roughnessMipmapper.generateMipmaps(child.material);

                }

            });
            logoScene = gltf.scene;
            logoScene.position.z = -100;
            logoScene.scale.set(8,8,8);
            scene.add(gltf.scene);

            roughnessMipmapper.dispose();

        });

    });



/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 1)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.camera.left = - 7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(- 5, 5, 0)
scene.add(directionalLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height, 0.1, 2000)
camera.position.set(0, 0, 5)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 0, 0)
controls.enableDamping = true
controls.enabled = false;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
})
renderer.setClearColor(new THREE.Color(backgroundColor),0 );

renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

const pmremGenerator = new THREE.PMREMGenerator(renderer);
pmremGenerator.compileEquirectangularShader();

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () => {
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Rotate logo
    if (logoScene) {
        logoScene.rotation.y = logoScene.rotation.y + rotationSpeed;
    }

    // Rotate cloud particles
    cloudParticles.forEach(p => {
        p.rotation.z -= p.sbRotSpeed;
        p.position.x += 0.01;

        if (p.position.x > outOffScreenPos) {
            p.position.x = -outOffScreenPos;
        }
    });


    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

createClouds();
function createClouds() {

    let loader = new THREE.TextureLoader();
    loader.load("/textures/smoke.png", function (texture) {
        //texture is loaded

        let cloudGeo = new THREE.PlaneBufferGeometry(500, 500);
        let cloudMaterial = new THREE.MeshLambertMaterial({
            map: texture,
            transparent: true
        });


        for (let p = 0; p < 100; p++) {
            let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
            cloud.scale.set(0.3, 0.3, 0.3);
            cloud.position.set(
                random() * 800 - 400,
                -55,
                random() * 500 - 500
            );
            // set random rotation speed
            cloud.sbRotSpeed = 0.0001 + random() * 0.0001;
                
            cloud.rotation.x = Math.PI;
            cloud.rotation.y = (2 * Math.PI) / 2.0;
            cloud.rotation.z = random() * 2 * Math.PI;
            cloud.material.opacity = 0.55;
            cloudParticles.push(cloud);
            scene.add(cloud);
        }
    });

}